<template>
  <FormCard v-if="role" :config="general_data" @DataSaved="UpdateData" />
  <div v-else class="loading">
    <CSpinner color="secondary" size="lg" />
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import FormCard from "../../../common/form/Card";
import GetOptions from "../../../common/form/options";

export default {
  model: {
    prop: "role",
    event: "change",
  },
  props: ["role"],
  components: {
    FormCard,
  },
  // watch: {
  //   "pp.category__macrocategory_id": function (nV, oV) {
  //     console.log("changed macrocategory", nV, oV);
  //     // this.UpdateMacrocategoryOptions();
  //     this.pp.category__parent_id = null;
  //     // this.UpdateLtypeOptions();
  //     this.pp.ptype_id = null;
  //   },
  //   "pp.category__parent_id": function (nV, oV) {
  //     console.log("changedparent", nV, oV);
  //     // this.UpdateCategoryOptions();
  //     this.pp.category_id = null;
  //   },
  // },
  methods: {
    UpdateData(data) {
      // console.log("UpdateData")
      // this.role = data
      this.$emit("change", data);
    },
  },
  computed: {
    general_data() {
      return {
        title: this.$t("role.role.tab_info.card_general.title"),
        data: this.role,
        fields: {
          name: {
            title: this.$t("models.role.name"),
            description: this.$t("models.role.name_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
          },
          "family.f_type": {
            title: this.$t("models.family.f_type"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [2, 10],
            options: [
              {
                value: "1",
                label: this.$t("models.family.f_type_option1_info"),
              },
              {
                value: "2",
                label: this.$t("models.family.f_type_option2_info"),
              },
              {
                value: "3",
                label: this.$t("models.family.f_type_option3_info"),
              },
            ],
            description: this.$t("models.family.f_type_info"),
          },
          "family.name": {
            title: this.$t("models.family.name"),
            description: this.$t("models.family.name_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
            enabled: this.role.family.f_type != "1",
          },
          tax_exempt: {
            title: this.$t("models.role.tax_exempt"),
            description: this.$t("models.role.tax_exempt_info"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [2, 10],
            options: [
              { value: true, label: this.$t("common.bool.false") },
              { value: false, label: this.$t("common.bool.true") },
            ],
            enabled: this.role.family.f_type != "1",
          },
          tax_number: {
            title: this.$t("models.role.tax_number"),
            description: this.$t("models.role.tax_number_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
            enabled:
              this.role.family.f_type != "1" && this.role.tax_exempt == false,
          },
          tel: {
            title: this.$t("models.role.phone"),
            description: this.$t("models.role.phone_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
          },
          url: {
            title: this.$t("models.role.web"),
            description: this.$t("models.role.web_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
          },
          r_type: {
            title: this.$t("models.role.r_type"),
            description: this.$t("models.role.r_type_info"),
            type: "CSelect",
            size: 12,
            subsize: [2, 10],
            options: [
              { value: 1, label: this.$t("models.role.r_type_option1_info") },
              { value: 2, label: this.$t("models.role.r_type_option2_info") },
              { value: 3, label: this.$t("models.role.r_type_option3_info") },
              { value: 4, label: this.$t("models.role.r_type_option4_info") },
              { value: 5, label: this.$t("models.role.r_type_option5_info") },
            ],
          },

          country_id: {
            title: this.$t("models.role.country"),
            description: this.$t("models.role.country_info"),
            type: "CSelect",
            size: 12,
            subsize: [2, 10],
            options: GetOptions({
              dm: GetDataManager("geo_country"),
              reducer: function (map, obj) {
                map.push({ value: obj.code, label: obj.name });
                return map;
              },
              reducer_cache: "geo_country",
            }),
          },

          "family.slug": {
            title: this.$t("models.family.slug"),
            description: this.$t("models.family.slug_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
          },

          // description: {
          //   title: "Descrizione",
          //   description: "compila il campo nome",
          //   type: "CKInput",
          //   size: 12,
          //   subsize: [2, 10],
          //   translate: "translations",
          // },
          // production_type_id: {
          //   title: "Tipo produzione",
          //   description: "compila il campo",
          //   type: "CSelect",
          //   size: 12,
          //   subsize: [2, 10],
          //   options: GetOptions({
          //     dm: GetDataManager("structure_production"),
          //     query_cache: "structure_production",
          //     cur_lang: this.cur_lang
          //   }),
          // },
          // ptype_id: {
          //   title: "Tipo logistica",
          //   description: "compila il campo",
          //   type: "CSelect",
          //   size: 12,
          //   subsize: [2, 10],
          //   options: GetOptions({
          //     dm: GetDataManager("structure_ltype"),
          //     query: new Query().where(
          //       "macrocategory_id",
          //       "equal",
          //       this.pp.category__macrocategory_id
          //     ),
          //     query_cache: "structure_ltype_"+this.pp.category__macrocategory_id,
          //     cur_lang: this.cur_lang
          //   }),
          // },
          // category__macrocategory_id: {
          //   title: "Tipo prodotto",
          //   description: "compila il campo",
          //   type: "CSelect",
          //   size: 12,
          //   subsize: [2, 10],
          //   options: GetOptions({
          //     dm: GetDataManager("structure_ptype"),
          //     query_cache: "structure_ptype",
          //     cur_lang: this.cur_lang
          //   }),
          // },
          // category__parent_id: {
          //   title: "Macrocategoria",
          //   description: "compila il campo",
          //   type: "CSelect",
          //   size: 12,
          //   subsize: [2, 10],
          //   options: GetOptions({
          //     dm: GetDataManager("structure_category_browser"),
          //     query: new Query().where(
          //       new Predicate("parent_id", "equal", null).and(
          //         "macrocategory_id",
          //         "equal",
          //         this.pp.category__macrocategory_id
          //       )
          //     ),
          //     query_cache: "structure_category_browser_M"+this.pp.category__macrocategory_id,
          //     cur_lang: this.cur_lang
          //   }),
          // },
          // category_id: {
          //   title: "Categoria",
          //   description: "compila il campo",
          //   type: "CSelect",
          //   size: 12,
          //   subsize: [2, 10],
          //   options: GetOptions({
          //     dm: GetDataManager("structure_category_browser"),
          //     query: new Query().where(
          //       new Predicate(
          //         "parent_id",
          //         "equal",
          //         this.pp.category__parent_id
          //       ).and(
          //         "macrocategory_id",
          //         "equal",
          //         this.pp.category__macrocategory_id
          //       ).and(
          //         "parent_id",
          //         "greaterthan",
          //         0
          //       )
          //     ),
          //     query_cache: "structure_category_browser_M"+this.pp.category__macrocategory_id+"_P"+this.pp.category__parent_id,
          //     cur_lang: this.cur_lang
          //   }),
          // },
          // certifications: {
          //   title: "Certificazioni",
          //   description: "compila il campo",
          //   type: "CInputCheckbox",
          //   size: 12,
          //   subsize: [2, 10],
          //   options: GetOptions({
          //     dm: GetDataManager("structure_certification"),
          //     reducer_cache: "certifications",
          //   }),
          //   search: true,
          // },
          // ageclass_id: {
          //   title: "Classe di età",
          //   description: "compila il campo",
          //   type: "CSelect",
          //   size: 12,
          //   subsize: [2, 10],
          //   options: GetOptions({
          //     dm: GetDataManager("structure_ageclass"),
          //     query_cache: "structure_ageclass",
          //     cur_lang: this.cur_lang
          //   }),
          // },
          // tax_rate_id: {
          //   title: "Aliquota iva",
          //   description: "compila il campo",
          //   type: "CSelect",
          //   size: 12,
          //   subsize: [2, 10],
          //   options: GetOptions({
          //     dm: GetDataManager("geo_tax"),
          //     query: new Query().where(
          //       "country_id",
          //       "equal",
          //       this.$store.state.role.country_id
          //     ),
          //     query_cache: "geo_tax_"+this.$store.state.role.country_id,
          //   }),
          // },
        },
        dm: GetDataManager("auth_frole", []),
      };
    },
  },
};
</script>
